<template>
    <el-dialog :title="inputForm.ticketingServerName" :append-to-body="true" :fullscreen="true"
               :close-on-click-modal="false" :visible.sync="visible" v-if="visible">
        <el-form size="small" :model="inputForm" ref="inputForm" label-width="160px" :disabled="method == 0" v-loading="loading">
            <div class="el-descriptions__title el-descriptions p_l1 p_b2">基本信息</div>
            <el-form-item label="服务名称：" prop="ticketingServerName">{{inputForm.ticketingServerName}}</el-form-item>
            <el-form-item label="商品图片：" prop="imgList">
                <ImgUpload :imgAskObj="imgUploadRequire" @getImgList="getImgList" v-if="method == 1"></ImgUpload>
                <div class="imgList" @click="checkImage" v-else>
                    <el-image :src="item" fit="cover" v-for="(item, index) in inputForm.ticketingServerImage" :key="index"
                              class="img" :preview-src-list="inputForm.ticketingServerImage"></el-image>
                </div>
            </el-form-item>
            <el-form-item label="商品视频：" prop="ticketingServerVideo">
                <el-col :span="8">
                    <ImgUpload :imgAskObj="videoUploadRequire" @getImgList="getVideoList"
                               v-if="method == 1"></ImgUpload>
                    <video controls :src="inputForm.ticketingServerVideo" class="w100" v-else></video>
                </el-col>
            </el-form-item>
            <el-form-item label="状态：" prop="ticketingServerState">
                <el-radio-group v-model="inputForm.ticketingServerState">
                    <el-radio label="0">启用</el-radio>
                    <el-radio label="1">禁用</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="时长（小时）：" prop="ticketingServerHour">
                <el-input v-model="inputForm.ticketingServerHour" maxlength="2" placeholder="请输入时长（0-99）"
                          onkeyup="value=value.replace(/[^\d]/g,'')"
                          oninput="if(value>99)value=99;if(value<0)value=0" clearable style="width: 35.5%"></el-input>
            </el-form-item>
            <el-form-item label="适用范围：" prop="ticketingServerRange">
                <el-input v-model="inputForm.ticketingServerRange" maxlength="100" placeholder="请输入适用范围（限100字）" clearable
                          style="width: 35.5%"></el-input>
            </el-form-item>
            <el-row>
                <el-col :span="6" class="m_r1">
                    <el-form-item label="使用时间：" prop="ticketingServerStartTime" :rules="[{required: true, message: '使用开始时段不能为空', trigger: 'change'}]">
                        <el-time-picker value-format="HH:mm:ss"
                                        format="HH:mm:ss" :picker-options="startTimeOptions"
                                        v-model="inputForm.ticketingServerStartTime" placeholder="开始时段"
                                        style="width: 100%">
                        </el-time-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label-width="0" prop="ticketingServerEndTime" :rules="[{required: true, message: '使用结束时段不能为空', trigger: 'change'}]">
                        <el-time-picker value-format="HH:mm:ss"
                                        format="HH:mm:ss" :picker-options="endTimeOptions"
                                        v-model="inputForm.ticketingServerEndTime" placeholder="结束时段"
                                        style="width: 100%">
                        </el-time-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <div v-if="method == 0">
                <el-form-item label="添加人：">{{inputForm.createUser}}</el-form-item>
                <el-form-item label="添加日期：">{{inputForm.createTime}}</el-form-item>
                <el-form-item label="最后修改时间：">{{inputForm.updateTime}}</el-form-item>
            </div>
            <div class="el-descriptions__title el-descriptions p_l1 p_t2 p_b2 b_t">服务规格</div>
            <div class="specifications">
                <el-row class="text_center">
                    <el-col :span="2" class="p_all1 b_d">序号</el-col>
                    <el-col :span="6" class="p_all1 b_r b_t b_b" v-if="inputForm.ticketingServerName == '人工讲解'">人数</el-col>
                    <el-col :span="6" class="p_all1 b_r b_t b_b" v-if="inputForm.ticketingServerName == '刺绣服务'">年龄</el-col>
                    <el-col :span="6" class="p_all1 b_r b_t b_b">语言</el-col>
                    <el-col :span="7" class="p_all1 b_r b_t b_b">价格（元）</el-col>
                    <el-col :span="3" class="p_all1 b_r b_t b_b" v-if="method == 1">操作</el-col>
                </el-row>
                <el-row v-for="(item, index) in inputForm.ticketingServerSkuVoList" :key="index" class="text_center">
                    <el-col :span="2" class="b_r b_b b_l">
                        <el-form-item label-width="0">{{index+1}}</el-form-item>
                    </el-col>
                    <el-col :span="6" class="b_r b_b" v-if="inputForm.ticketingServerName == '人工讲解'">
                        <el-form-item :prop="`ticketingServerSkuVoList.${index}.numberOfPeopleId`"
                                      :rules="[{required: true, message: '人数不能为空', trigger: 'change'}]" label-width="0">
                            <el-select v-if="item.isEdit" size="small" v-model="item.numberOfPeopleId" placeholder="请选择人数"
                                       style="width: 100%;">
                                <el-option
                                        v-for="item in peopleOpt"
                                        :key="item.id"
                                        :label="item.numberOfPeople"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                            <div v-else>{{peopleOpt.filter(c => c.id == item.numberOfPeopleId)[0].numberOfPeople}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="b_r b_b" v-if="inputForm.ticketingServerName == '刺绣服务'">
                        <el-form-item :prop="`ticketingServerSkuVoList.${index}.ageId`"
                                      :rules="[{required: true, message: '年龄不能为空', trigger: 'change'}]" label-width="0">
                            <el-select v-if="item.isEdit" size="small" v-model="item.ageId" placeholder="请选择年龄"
                                       style="width: 100%;">
                                <el-option
                                        v-for="item in ageOpt"
                                        :key="item.id"
                                        :label="item.age"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                            <div v-else>{{ageOpt.filter(c => c.id == item.ageId)[0].age}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="b_r b_b">
                        <el-form-item :prop="`ticketingServerSkuVoList.${index}.languageId`"
                                      :rules="[{required: true, message: '语言不能为空', trigger: 'change'}]" label-width="0">
                            <el-select v-if="item.isEdit" size="small" v-model="item.languageId" placeholder="请选择语言"
                                       style="width: 100%;">
                                <el-option v-for="item in languageOpt"
                                           :key="item.id"
                                           :label="item.language"
                                           :value="item.id">
                                </el-option>
                            </el-select>
                            <div v-else>{{languageOpt.filter(c => c.id == item.languageId)[0].language}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="7" class="b_r b_b">
                        <el-form-item :prop="`ticketingServerSkuVoList.${index}.price`"
                                      :rules="[{required: true, message: '价格不能为空', trigger: 'blur'},{validator:validator.isPrice, trigger:'blur'}]" label-width="0">
                            <el-input v-if="item.isEdit" size="small" v-model="item.price" placeholder="请输入价格"
                                      maxlength="6" @input="priceChange(item)"
                                      style="width: 100%;"></el-input>
                            <div v-else>{{item.price}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="operationItem b_r b_b" v-if="method == 1">
                        <el-button type="text" size="mini" @click="editSpecifications(item, index)">{{item.isEdit ?
                            '完成':'编辑'}}
                        </el-button>
                        <el-button type="text" size="mini" @click="delSpecifications(index)">删除</el-button>
                    </el-col>
                </el-row>
                <div class="text_center p_t1">
                    <el-button size="small" type="primary" @click="addSpecifications()" v-if="method == 1">增加
                    </el-button>
                </div>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="visible = false">取消</el-button>
            <el-button size="small" type="primary" @click="affirm()" v-noMoreClick v-if="method == 1">确认</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import ImgUpload from "@/components/imgUpload/imgUpload";

    export default {
        components: {ImgUpload},
        data() {
            return {
                visible: false,
                loading: false,
                method: 0,
                row: {},
                inputForm: {},
                peopleOpt: [],
                ageOpt: [],
                languageOpt: [],
                videoUploadRequire: {
                    btnName: '上传视频', // 按钮名称
                    imgCount: 1, // 上传数量 0表示不限制数量
                    imgList: [], // 已上传的文件
                    uploadPath: "course",
                    fileType: ['mp4'], // 上传文件类型
                    listType: 'text', // 图片缩略图类型
                    imgNum: 5, // 上传文件兆数 0表示不限兆数
                    fileTypeMark: 2, // 上传文件类型标识 1：图片；2：视频
                },
                imgUploadRequire: {
                    imgCount: 9, // 上传数量 0表示不限制数量
                    imgList: [], // 已上传的文件
                    uploadPath: "course",
                    imgSize: '750*750', // 图片尺寸
                    listType: 'picture-card', // 图片缩略图类型
                    imgNum: 1, // 上传文件兆数 0表示不限兆数
                },
            }
        },
        computed: {
            // 开始时间
            startTimeOptions() {
                return {
                    selectableRange: `00:00:00-${this.inputForm.ticketingServerEndTime ? this.inputForm.ticketingServerEndTime : '23:59:59'}`
                }
            },
            // 结束时间
            endTimeOptions() {
                return {
                    selectableRange: `${this.inputForm.ticketingServerStartTime ? this.inputForm.ticketingServerStartTime : '00:00:00'}-23:59:59}`
                }
            }
        },
        methods: {
            // 初始化 row：数据；method：方法 0查看；1编辑
            init(id, method) {
                this.videoUploadRequire.imgList = []
                this.imgUploadRequire.imgList = []
                this.inputForm = {}
                this.method = method
                this.visible = true
                this.allNumberOfPeople();
                this.allAge();
                this.allLanguage();
                this.sysGetTicketingServerById(id);
            },
            // 获取服务详情
            sysGetTicketingServerById(id) {
                this.$axios(this.api.order.sysGetTicketingServerById, {ticketingServerId: id}).then((res) => {
                    if (res.status) {
                        this.inputForm = res.data
                        this.inputForm.ticketingServerSkuVoList.map(item => {
                            this.$set(item, 'isEdit', false)
                        })
                        if(res.data.ticketingServerVideo != '') {
                            this.videoUploadRequire.imgList = [{
                                name: '商品视频',
                                url: res.data.ticketingServerVideo
                            }]
                        }
                        this.inputForm.ticketingServerImage.map(item => {
                            this.imgUploadRequire.imgList.push({
                                url: item
                            })
                        })
                    } else {
                        this.$message.error('查询失败');
                    }
                })
            },
            // 获取人数配置
            allNumberOfPeople() {
                this.$axios(this.api.order.allNumberOfPeople).then((res) => {
                    if (res.status) {
                        this.peopleOpt = res.data
                    }
                })
            },
            // 获取年龄配置
            allAge() {
                this.$axios(this.api.order.allAge).then((res) => {
                    if (res.status) {
                        this.ageOpt = res.data
                    }
                })
            },
            // 获取语言配置
            allLanguage() {
                this.$axios(this.api.order.allLanguage).then((res) => {
                    if (res.status) {
                        this.languageOpt = res.data
                    }
                })
            },
            // 增加规格
            addSpecifications() {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        /*this.inputForm.ticketingServerSkuVoList.map(item => {
                            this.$set(item, 'isEdit', false)
                        })*/
                        this.inputForm.ticketingServerSkuVoList.push({
                            numberOfPeopleId: null,
                            ageId: null,
                            languageId: null,
                            price: null,
                            isEdit: true,
                        })
                    }
                })
            },
            // 编辑规格
            editSpecifications(item, index) {
                if (item.isEdit) {
                    if (item.numberOfPeopleId == null && this.inputForm.ticketingServerName == '人工讲解') {
                        this.$message('请选择人数');
                        return false
                    }
                    if (item.ageId == null && this.inputForm.ticketingServerName == '刺绣服务') {
                        this.$message('请选择年龄');
                        return false
                    }
                    if (item.languageId == null) {
                        this.$message('请选择语言');
                        return false
                    }
                    if (item.price == null || item.price == '') {
                        this.$message('请输入价格');
                        return false
                    }
                    if(!this.validate.isPrice(item.price)) {
                        return false
                    }
                    this.$set(this.inputForm.ticketingServerSkuVoList[index], 'isEdit', !item.isEdit)
                } else {
                    this.$set(this.inputForm.ticketingServerSkuVoList[index], 'isEdit', !item.isEdit)
                }
            },
            // 删除规格
            delSpecifications(index) {
                this.$confirm(`确定删除这条数据吗?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.inputForm.ticketingServerSkuVoList.splice(index, 1);
                });
            },
            // 价格修改
            priceChange(item) {
                let value = item.price.replace(/[^\d^\.]+/g, '')
                if (value > 500) {
                    this.$set(item, 'price', 500)
                } else {
                    this.$set(item, 'price', value)
                }
            },
            // 确认
            affirm() {
                let isEditFlag = 0
                if(this.inputForm.ticketingServerSkuVoList.length == 0) {
                    this.$message('请增加服务规格');
                    return
                }
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        let ticketingServerSkuDtoList = [];
                        let p = JSON.parse(JSON.stringify(this.inputForm).replace(/ticketingServerSkuVoList/g,"ticketingServerSkuDtoList"))
                        p.ticketingServerSkuDtoList.map(item => {
                            ticketingServerSkuDtoList.push({
                                ageId: item.ageId,
                                languageId: item.languageId,
                                numberOfPeopleId: item.numberOfPeopleId,
                                price: item.price,
                            })
                            if(item.isEdit) {
                                isEditFlag++
                            }
                        })
                        if(isEditFlag > 0) {
                            this.$message('请完成服务规格');
                            isEditFlag = 0
                            return
                        }
                        this.loading = true;
                        p.ticketingServerSkuDtoList = ticketingServerSkuDtoList
                        this.$axios(this.api.order.sysSaveTicketingServer, p, 'post').then((res) => {
                            if (res.status) {
                                this.$message.success('提交成功');
                                this.loading = false;
                                this.visible = false;
                                this.$emit('refreshDataList')
                            } else {
                                this.$message.error(res.msg);
                                this.loading = false;
                            }
                        })
                    }
                })
            },
            // 点击图片预览添加下载按钮
            checkImage() {
                let a = document.querySelector('.el-image-viewer__actions__inner');
                $(a).append(`<i class="el-icon-download"></i>`)
                $(".el-icon-download").click(function () {
                    let imgsrc = document.querySelector('.el-image-viewer__img').src, name = 'photo'
                    let image = new Image();
                    // 解决跨域 Canvas 污染问题
                    image.setAttribute("crossOrigin", "anonymous");
                    image.onload = function () {
                        let canvas = document.createElement("canvas");
                        canvas.width = image.width;
                        canvas.height = image.height;
                        let context = canvas.getContext("2d");
                        context.drawImage(image, 0, 0, image.width, image.height);
                        let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
                        let a = document.createElement("a"); // 生成一个a元素
                        let event = new MouseEvent("click"); // 创建一个单击事件
                        a.download = name; // 设置图片名称
                        a.href = url; // 将生成的URL设置为a.href属性
                        a.dispatchEvent(event); // 触发a的单击事件
                    };
                    image.src = imgsrc;
                });
            },
            // 获取上传图片数据
            getImgList(data) {
                this.inputForm.ticketingServerImage = []
                data.map(item => {
                    this.inputForm.ticketingServerImage.push(item.imgUrl)
                })
            },
            // 获取上传视频数据
            getVideoList(data) {
                if(data.length > 0) {
                    this.inputForm.ticketingServerVideo = data[0].imgUrl
                } else {
                    this.inputForm.ticketingServerVideo = ''
                }
            },
        },
    }
</script>

<style scoped>
    .specifications >>> .el-form-item--small.el-form-item {
        width: 80% !important;
        margin: 18px auto;
    }

    .operationItem {
        line-height: 32px;
        padding: 18px 0;
    }

    .imgList .img {
        margin-right: 10px;
        width: 10%;
    }
</style>
